<template>
  <b-container fluid class="mb-6 mt-6 p-0">
    <b-row>
      <b-col>
        <div class="card card-custom gutter-b">
          <div class="mt-0">
            <template-table
              :column="column"
              :data="data"
              :paging="paging"
              :tableAction="false"
              :selectAction="false"
              :searchAction="false"
            >
              <template v-slot:body="{ item }">
                <td>
                  <span class="font-weight-bolder">
                    {{ $moment(item.date * 1000).format('HH:mm, DD/MM/YYYY') }}
                  </span>
                </td>
                <td>
                  <span class="font-weight-bolder"> {{ item.timeFrame }} </span>
                </td>
                <td>
                  <span
                    :style="`color:${item.color}`"
                    class="font-weight-bolder"
                  >
                    {{ parseInt(item.systolic) }}/{{ parseInt(item.diastolic) }}
                  </span>
                  <span class="unit"> mmHg</span>
                </td>
                <td>
                  <span class="font-weight-bolder">
                    {{ item.pulseRate }}
                  </span>
                  <span class="unit">lần/phút</span>
                </td>
                <td>
                  <span class="font-weight-bolder"> {{ item.note }}</span>
                </td>
              </template>
            </template-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'BloodPressure',
  props: {
    time: {
      type: Object,
    },
    idUser: {
      type: [String, Number],
    },
  },
  data() {
    return {
      column: [
        {
          key: 'dateInput',
          label: 'Ngày nhập',
          sortable: false,
        },
        {
          key: 'timeFrame',
          label: 'Khung giờ',
          sortable: false,
        },
        {
          key: 'result',
          label: 'Huyết áp',
          sortable: false,
        },
        {
          key: 'hearRate',
          label: 'Nhịp tim',
          sortable: false,
        },
        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: 1,
      },
    };
  },
  watch: {
    paging: {
      handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      deep: true,
    },
    time: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal.value;
        this.loadData();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    id() {
      return this.idUser;
    },
  },

  methods: {
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get(`Admin/BloodPressure/Input`, {
          params: {
            ...this.searchParams,
            ...this.paging,
            size: this.paging.pageSize,
            patientId: this.id,
          },
        })
        .then(({ meta, data }) => {
          this.data = data || [];

          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: #b5b5c3;
}
</style>
